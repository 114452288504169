<template>
  <AppNavbar
    :current-app="currentApp"
    :items="apps"
  />
</template>

<script>
import AppNavbar from '@widgets/AppNavbar.vue';

export default {
  name: 'AppNav',
  components: { AppNavbar },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentApp: process.env.VUE_APP_TITIFY_ADDRESS,
      apps: [
        {
          text: this.$t('apps.titify'),
          href: process.env.VUE_APP_TITIFY_ADDRESS,
        },
        {
          text: this.$t('apps.front_archivist'),
          href: process.env.VUE_APP_ARCHIVIST_ADDRESS,
        },
        {
          text: this.$t('apps.front_goals'),
          href: process.env.VUE_APP_GOALS_ADDRESS,
        },
        {
          text: this.$t('apps.nk_front'),
          href: process.env.VUE_APP_LEARNING_ADDRESS,
        },
        {
          text: this.$t('apps.front_engagement_admin'),
          href: process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS,
        },
      ],
    };
  },
};
</script>
